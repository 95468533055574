import { ProductListItemRenderer } from '@graphcommerce/magento-product'
import { ProductListItemBundle } from '@graphcommerce/magento-product-bundle'

import { ProductListItemDownloadable } from '@graphcommerce/magento-product-downloadable'
import { ProductListItemGrouped } from '@graphcommerce/magento-product-grouped'

import { ProductListItemVirtual } from '@graphcommerce/magento-product-virtual'
import { ProductReviewSummary } from '@graphcommerce/magento-review'
import { ProductListItemConfigurable } from './ProductListItemConfigurable'
import { ProductListItemSimple } from './ProductListItemSimple'

export const productListRenderer: ProductListItemRenderer = {
  SimpleProduct: (props) => (
    <ProductListItemSimple
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
    />
  ),
  ConfigurableProduct: (props) => (
    <ProductListItemConfigurable
      {...props}
      aspectRatio={[1, 1]}
      swatchLocations={{
        topLeft: [],
        topRight: [],
        bottomLeft: [],
        bottomRight: [],
      }}
      bottomLeft={<ProductReviewSummary {...props} />}
    />
  ),
  BundleProduct: (props) => (
    <ProductListItemBundle
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
    />
  ),
  VirtualProduct: (props) => (
    <ProductListItemVirtual
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
    />
  ),
  DownloadableProduct: (props) => (
    <ProductListItemDownloadable
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
    />
  ),
  GroupedProduct: (props) => (
    <ProductListItemGrouped
      {...props}
      aspectRatio={[1, 1]}
      bottomLeft={<ProductReviewSummary {...props} />}
    />
  ),
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore GiftCardProduct is only available in Commerce
  // GiftCardProduct: (props) => (
  //   <ProductListItem {...props} aspectRatio={[1, 1]} />
  // ),
}
